import React from 'react'
import { Campaign } from '@/services/api/campaign'
import Type from '../Type'
import ClosedBackersAmount from './ClosedBackersAmount'
import Footer from './Footer'
import PriorRaises from './PriorRaises'

interface Props {
  campaign: Campaign
  url: string
}

const PreviouslyFundedSection: React.FC<Props> = ({ campaign, url }) => {
  return (
    <>
      <ClosedBackersAmount campaign={campaign} />
      <PriorRaises campaign={campaign} />

      <Footer cta="View Details" url={url} ctaVariant="link">
        <Type as="p" variant="caption-sm" className="text-core-gray-600">
          {Number(campaign.backers).toLocaleString()} investors
        </Type>
      </Footer>
    </>
  )
}

export default PreviouslyFundedSection
