import React from 'react'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { formatMoney } from '@/utils'
import Type from '../Type'

interface Props {
  campaign: Campaign
  isDisclaimed?: boolean
  className?: string
}

const ClosedBackersAmount: React.FC<Props> = ({
  campaign,
  className,
  isDisclaimed,
}) => {
  const siteAssets = useSiteAssets()
  const { backers, priorRaiseTotal, amountRaised } = campaign

  const resolvedBackers = siteAssets?.usePriorRaisesLayout
    ? priorRaiseTotal.backers + backers
    : backers
  const formattedBackers = resolvedBackers.toLocaleString()

  const resolvedAmount = siteAssets?.usePriorRaisesLayout
    ? priorRaiseTotal.amount + amountRaised
    : amountRaised
  const formattedAmount = formatMoney(resolvedAmount, {
    zeroFractionDigits: true,
  })

  const personOrPeople = resolvedBackers === 1 ? 'person' : 'people'

  return (
    <div className={className}>
      <Type as="p" variant="paragraph-lg">
        <span className="font-bold">{formattedBackers}</span> {personOrPeople}{' '}
        raised <span className="font-bold">{formattedAmount}</span>
        {isDisclaimed && '*'}
      </Type>
    </div>
  )
}

export default ClosedBackersAmount
